<template>
  <div class="textarea-wrap">
    <textarea
      ref="textarea"
      :placeholder="placeholder"
      v-model="text"
      @keydown="setHeight()"
      rows="1"
    >
    </textarea>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'text-area',

  props: {
    reset: '',
    placeholder: { String },
    content: [String],
    mode: {
      type: [String],
      default: 'add'
    }
  },

  computed: {
    text: {
      get () {
        return this.content || ''
      },
      set (newVal) {
        return this.$emit('input', newVal)
      }
    }
  },

  watch: {
    text () {
      let self = this
      self.$emit('input', this.text)
    },

    reset () {
      if (!isNaN(this.reset)) {
        this.text = ''
      }
    }
  },

  mounted () {
    this.setHeight()

    if (this.mode === 'edit') {
      this.text = this.content
    }
  },

  beforeUpdate () {
    if (this.mode === 'edit') {
      this.text = this.content
    }
  },

  methods: {
    setHeight () {
      let el = this.$refs.textarea

      setTimeout(() => {
        el.style.cssText = 'height: auto;';
        el.style.cssText = 'height:' + el.scrollHeight + 'px';
      }, 0)
    }
  }
}
</script>
